import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { QRCodeCanvas } from "qrcode.react";
import {
    Box,
    Button,
    TextField,
    Typography,
    IconButton,
    Grid,
    Paper,
    Divider,
} from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import { setStorageValue } from "core/useLocalStorage";
import { SOFTWARE_TOKEN_MFA } from "core/AuthHelper";

const EnableMfaTotp = ({
    handleLogoutClick,
    type,
    onClose,
    onChange
}) => {

    const [qrCode, setQrCode] = useState(null);
    const [totpCode, setTotpCode] = useState("");
    const [user, setUser] = useState(false);
    const [totpSecret, setTotpSecret] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        setupTotp();
    }, []);

    const setupTotp = async () => {
        try {
            let curUser = await Auth.currentAuthenticatedUser();
            setUser(curUser);

            const totpSecret = await Auth.setupTOTP(curUser);
            setTotpSecret(totpSecret); // Set the TOTP secret

            const qrCodeUrl = `otpauth://totp/Tilley:${curUser.username}?secret=${totpSecret}&issuer=Tilley`;
            setQrCode(qrCodeUrl); // Set the QR code to display
        } catch (error) {
            console.error("Error setting up TOTP:", error);
        }
    };

    const verifyTotp = async () => {
        try {
            if (!totpCode || totpCode.length !== 6) {
                console.error("Please enter a valid 6-digit TOTP code");
                return; // Ensure valid code input
            }

            const result = await Auth.verifyTotpToken(user, totpCode);
            const updateMFA = await Auth.setPreferredMFA(user, "TOTP"); // Set TOTP as preferred MFA

            if (updateMFA === 'SUCCESS') {
                setStorageValue(STORAGE_VALUE_MFA, SOFTWARE_TOKEN_MFA);
            }

            if (type !== 'login') {
                onClose();
                onChange && onChange();
            }
        } catch (error) {
            console.error("Error verifying TOTP code:", error);
            setError("Invalid code. Please try again."); // Display error message
        }
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(totpSecret);
    };

    const handleChange = (e) => {
        const value = e.target.value;

        // Allow only numbers and restrict input to 6 digits
        if (value === '' || /^\d{0,6}$/.test(value)) {
            setTotpCode(value); // Update the code value only if valid
        }
    };

    return (
        <div style={{ textAlign: "center" }}>
            <Grid container justifyContent="center" className="enableMFAGrid">
                <Paper className="enableMFA" elevation={3} style={{ padding: '20px', maxWidth: '600px' }}>

                    {type == 'login' && <Box display="flex" justifyContent="center" mb={2}>
                        <img
                            src="/images/Tilley_logo_RGB_300.png"
                            alt="Logo"
                            style={{ marginBottom: '10px', height: '100px' }}
                        />
                    </Box>}
                    <Typography variant="h5" component="h2" align="center" gutterBottom>
                        Setup Security Key
                    </Typography>
                    <Typography variant="body2" component="h2" align="center" gutterBottom>
                        Please scan the QR code below using the authenticator app of your choice.
                    </Typography>

                    <Divider />

                    <Box display="flex" justifyContent="center" mb={2}>
                        <QRCodeCanvas value={qrCode} style={{ height: '170px', width: '170px' }} />
                    </Box>

                    <Typography
                        variant="body2"
                        align="center"
                        style={{ wordBreak: 'break-all' }}
                        gutterBottom
                    >
                        {totpSecret}
                        <IconButton
                            onClick={handleCopy}
                            size="small"
                            style={{ marginLeft: '10px' }}
                            aria-label="Copy Secret Key"
                        >
                            <ContentCopy fontSize="small" />
                        </IconButton>
                    </Typography>

                    <TextField
                        label="Code*"
                        variant="outlined"
                        fullWidth
                        value={totpCode}
                        onChange={handleChange}
                        margin="normal"
                        InputProps={{
                            style: {
                                border: '1px solid rgba(0, 0, 0, 0.23) !important',
                                borderRadius: '4px',
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                            style: {
                                fontSize: '4rem',
                            },
                        }}
                        style={{ marginBottom: '20px' }}
                    />
                    {/* Display error message below input */}
                    {error && (
                        <Typography variant="body2" color="error" style={{ marginBottom: '10px' }}>
                            {error}
                        </Typography>
                    )}

                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={verifyTotp}
                        style={{ marginTop: '20px' }}
                    >
                        Confirm
                    </Button>

                    {type == 'login' && <Button
                        variant="text"
                        color="primary"
                        fullWidth
                        onClick={handleLogoutClick}
                        style={{ marginTop: '10px', textTransform: 'none', border: 'none' }}
                    >
                        Back to Sign In
                    </Button>}
                </Paper>
            </Grid>
        </div>
    );
};

export default EnableMfaTotp;
