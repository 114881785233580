import { useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { _sessionManager, UsersApiHelper } from 'api/ApiHelper';
import { AppContext } from 'App';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { Dialog, IconButton } from '@mui/material'
import { DeleteForeverOutlined } from '@mui/icons-material'
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import { CropProfileEditDialog } from './../Broker/CropProfileEditDialog';
import { useDialog } from '@datanac/datanac-mui-toolkit/dist/hooks'
import { ApiHelper } from '@datanac/datanac-api-toolkit/dist/api-helper';
import { formatNumber } from 'utility/formatters';
import { v4 } from 'uuid';


export default function CropProfileGrid({
    producer_token,
    onAddCropProfile = false,
    onClose = null
}) {
    const { globalState } = useContext(AppContext);

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });

    const { enqueueSnackbar } = useSnackbar();

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const [editingCropProfile, setEditingCropProfile] = useState(null);

    // ---

    const [cropProfiles, setCropProfiles] = useState(null);

    useEffect(() => {
        if (onAddCropProfile) {
            handleAddCropProfile();
        }
    }, [onAddCropProfile]);

    const handleAddCropProfile = () => {
        setEditingCropProfile({});
    };

    const handleCloseModal = () => {
        setEditingCropProfile(null);
        if (onClose) onClose();
    };

    useEffect(() => {
        try {
            if (producer_token) {
                _loadCropProfiles();
            } else {
                setCropProfiles(null);
            }
        } catch (err) {
            handleError(err);
        }
    }, [producer_token]);

    const _loadCropProfiles = async () => {
        try {
            // Get Member:
            const _cropProfiles = await UsersApiHelper.users_selectObject("CropProfile", { is_active: true, producer_token: producer_token }, { producer_token: producer_token });
            
            // Filter out profiles without an 'id'
            const cropProfiles = _cropProfiles?.filter(profile => profile.id !== null) || [];
            setCropProfiles(cropProfiles);
        } catch (err) {
            handleError(err);
        }
    };
    

    // --- --- ---

    const handleChange_CropProfile = async (_newCropProfile) => {
        try {
            // Instantiate API helper
            const api = new ApiHelper({
                baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
                config: {},
                sessionManager: _sessionManager,
                _instance: null
            });

            // Include additional fields if onAddCropProfile is defined
            if (onAddCropProfile) {
                _newCropProfile = {
                    id: v4(),
                    producer_token: producer_token,
                    is_active: true,
                    ..._newCropProfile,
                };
            }

            // Make API POST request
            await api.callPost("marketing/CropProfile", _newCropProfile, globalState);

            // Reload crop profiles upon success
            _loadCropProfiles();
        } catch (err) {
            // Handle duplicate key error specifically
            if ((err.detail || err.message || JSON.stringify(err))?.toLowerCase()?.includes("duplicate")) {
                enqueueSnackbar("Crop Profiles must be unique. This entry already exists. Try another selection.", { preventDuplicate: true, variant: 'error' });
            } else {
                handleError(err);
            }
        }
    };



    const handleRowDelete_CropProfile = useCallback(async (row) => {
        confirmDialogActions.confirm({
            title: "Delete this entry?",
            prompt: "Are you sure you want to delete this entry?",
            promptDetail: null,
            callback: () => {
                _handleRowDeleteIntern_CropProfile(row);
            }
        })
    }, [confirmDialogActions]);

    const _handleRowDeleteIntern_CropProfile = async (row) => {
        const _row = { ...row };
        _row.is_active = false;

        handleChange_CropProfile(_row);
        _loadCropProfiles();
    };

    const columns = useMemo(() => [
        {
            field: 'year',
            headerName: 'Year',
            minWidth: 200,
            flex: 1
        },
        {
            field: 'commodity_name',
            headerName: 'Commodity',
            minWidth: 200,
            flex: 1
        },
        {
            field: 'marketing_goal_units',
            headerName: 'Marketing Goal',
            minWidth: 150,
            flex: 1,
            valueFormatter: ({ value }) =>
                formatNumber(value, 0)
        },
        {
            field: 'exchange_code',
            headerName: 'Exchange',
            minWidth: 120,
            flex: 1
        },
        {
            field: 'actions',
            headerName: 'Actions',
            minWidth: 150,
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <>
                    <IconButton onClick={() => setEditingCropProfile(params?.row)}>
                        <EastOutlinedIcon />
                    </IconButton>
                    <IconButton onClick={() => handleRowDelete_CropProfile(params?.row)}>
                        <DeleteForeverOutlined />
                    </IconButton>
                </>
            )
        }
    ], []);

    return (
        <>
            <DataGrid
                rows={cropProfiles || []}
                columns={columns}
                initialState={{
                    pagination: { paginationModel: { pageSize: isMobile ? 10 : 10 } },
                }}
                pageSizeOptions={[5, 10, 25]}
                autoHeight
            />
            <Dialog open={Boolean(editingCropProfile)}>
                <CropProfileEditDialog cropProfile={editingCropProfile} onClose={handleCloseModal}
                    onChange={handleChange_CropProfile} />
            </Dialog>

            {confirmDialogComponent}
        </>
    );
}