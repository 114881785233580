import { USER_ROLE_ADMIN } from '@datanac/datanac-api-toolkit/dist/api-helper';
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import { Autocomplete, Box, Divider, FormControl, Grid, IconButton, Menu, MenuItem, Stack, TextField, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';
import { UsersApiHelper } from 'api/ApiHelper';
import { AppContext } from 'App';
import { getBudgetYears } from 'components/Budget/helpers';
import { propUpdateReducer } from 'components/Core/propUpdateReducer';
import { StatefulControlledTextField } from 'components/Core/StatefulControlledTextField';
import { FarmVaultIcon, MarketingIcon } from 'components/Icons/Icons';
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatNumber, formatPercentage, formatPrice, formatProducerName } from 'utility/formatters';
import AgentAutoComplete from './AgentAutoComplete';
import TilleyDataGrid from 'components/shared/DataGrid/TilleyDataGrid';

export default function RiskSummaryBroker() {
    const { globalState, globalDispatch } = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });

    const [isLoadingCurrent, setIsLoadingCurrent] = useState(false);
    const [currentYear, setCurrentYear] = useState(globalState?.crop_year);
    const [currentBroker, setCurrentBroker] = useState("Broker");

    // ---

    const [brokers, setBrokers] = useState(null);
    const [isLoadingBrokers, setIsLoadingBrokers] = useState(false);

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const loadBrokers = async () => {
        try {
            if (globalState?.agent_token) {
                setIsLoadingBrokers(true);

                const _brokersFilter = {
                    agent_token: globalState?.agent_token,
                };
                // This is agent function. Do not specify producer_token or you will get permissions error.
                const _apiConfig = {
                    agent_token: globalState?.agent_token,
                };
                const _brokers = await UsersApiHelper.users_search("AgencyAgent", _brokersFilter, _apiConfig);
                setBrokers(_brokers);
                setIsLoadingBrokers(false);
            } else if (globalState?.user_role == USER_ROLE_ADMIN) {
                setIsLoadingBrokers(true);

                const _brokersFilter = {};
                // This is ADMIN function. Do not specify producer_token or agent_token or you will get permissions error.
                const _apiConfig = {};
                const _brokers = await UsersApiHelper.users_search("AgencyAgent", _brokersFilter, _apiConfig);

                setBrokers(_brokers);
                setIsLoadingBrokers(false);
            }
        } catch (err) {
            setIsLoadingBrokers(false);
            setBrokers(null);
            handleError(err);
        }
    }

    // --- --- ---

    const [reportData, setReportData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const _loadReportData = async () => {
        try {
            setIsLoading(true);

            const _filter = {
                agent_token: globalState?.agent_token,
                year: currentYear,
            };

            const promises = filter.filter_agent_token.map(async (agentToken) => {
                if (filter?.filter_agent_token && filter?.filter_agent_token.length) {
                    _filter.filter_agent_token = agentToken;
                }

                const apiClaims = {};
                if (globalState?.user_role != USER_ROLE_ADMIN) {
                    apiClaims.agent_token = globalState?.agent_token;
                }

                return await UsersApiHelper.users_search("PositionSummary", _filter, apiClaims);
            });

            const allResults = await Promise.all(promises);
            setReportData(allResults.flat());
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setReportData(null);
            handleError(err);
        }
    }

    // ---

    const [filter, dispatchFilter] = useReducer(propUpdateReducer, {
        filter_agent_token: globalState?.filter_agent_token || [globalState?.agent_token] || [],
        search_producer_name: globalState?.search_text_portfolio,
    });
    const selectedBroker = useMemo(() => {
        const _selectedBroker = brokers?.filter(broker =>
            filter?.filter_agent_token?.includes(broker?.agent_token.toLowerCase())
        ) || [];

        return _selectedBroker;
    }, [brokers, filter?.filter_agent_token, globalState?.agent_token]);

    useEffect(() => {
        globalDispatch({
            type: 'update',
            payload: {
                key: "filter_agent_token",
                value: filter?.filter_agent_token,//array
            },
        });
    }, [filter?.filter_agent_token]);

    const handleChangeAgentToken = async (agentTokens) => {
        let agents = agentTokens.map((item) => (item.agent_token));

        dispatchFilter({
            type: 'update',
            payload: { key: 'filter_agent_token', value: agents },
        });
    }

    const filterHandleChangeState = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        dispatchFilter({
            type: 'update',
            payload: { key: event.target.name, value: value },
        });

        // Persist search text?
        if (event.target.name == 'search_producer_name') {
            persistSearchText(event?.target?.value);
        }
    }

    const persistSearchText = async (searchText) => {
        globalDispatch({
            type: 'update',
            payload: {
                key: "search_text_portfolio",
                value: searchText,
            },
        });
    }

    const filteredReportData = useMemo(() =>
        reportData?.filter((row) => {
            if (filter?.search_producer_name) {
                return row.last_name?.toLowerCase().includes(filter?.search_producer_name.toLowerCase())
                    || row.first_name?.toLowerCase().includes(filter?.search_producer_name.toLowerCase())
                    || row.business_name?.toLowerCase().includes(filter?.search_producer_name.toLowerCase())
                    || row.producer_token?.toLowerCase().includes(filter?.search_producer_name.toLowerCase())
                    || formatProducerName(row)?.toLowerCase().includes(filter?.search_producer_name.toLowerCase());
            }
            return true;
        }),
        [reportData, filter?.search_producer_name]);

    // ---

    useEffect(() => {
        if (globalState?.agent_token || globalState?.user_role == USER_ROLE_ADMIN) {
            setIsLoadingBrokers(true);
            loadBrokers();
        }
    }, [globalState?.agent_token, globalState?.user_role, filter]);

    useEffect(() => {
        if (currentYear && (filter?.filter_agent_token || globalState?.agent_token)) {
            _loadReportData();
        }
    }, [currentYear, filter, globalState?.agent_token]);

    // --- --- ---

    const _formatProducerNameCallback = useCallback((row) => {
        return formatProducerName(row);
    }, []);

    // Memoize column definition
    const columns = useMemo(() => [
        {
            field: 'producer_token',
            headerName: 'Producer',
            minWidth: 200,
            valueGetter: (params) => {
                return _formatProducerNameCallback(params.row);
            },
            flex: 1,
        },
        {
            field: 'commodity_name',
            headerName: 'Commodity',
        },

        {
            field: 'production',
            headerName: 'Production',
            valueFormatter: (params) =>
                formatNumber(params.value, 0),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'production_guarantee',
            headerName: 'Guarantee',
            valueFormatter: (params) =>
                formatNumber(params.value, 0),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'marketing_goal_units',
            headerName: 'Goal',
            valueFormatter: (params) =>
                formatNumber(params.value, 0),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'unit_quantity_hedge',
            headerName: 'Hedged',
            valueFormatter: (params) =>
                formatNumber(params.value, 0),
            align: 'right',
            headerAlign: 'right',
        },
        {
            field: 'calc_percent_protected',
            headerName: '% Protected',
            valueGetter: (params) => {
                //params?.row?.unit_quantity_hedge / params?.row?.marketing_goal_units * 100;
                if (params?.row?.marketing_goal_units == 0) {
                    return null;
                } else {
                    return params?.row?.marketing_goal_units != 0 ? params?.row?.unit_quantity_hedge / params?.row?.marketing_goal_units : 0;
                }
            },
            valueFormatter: (params) =>
                params.value == null ? null : formatPercentage(params.value, 0),
            minWidth: 150,
            align: 'right',
            headerAlign: 'right',
        },


        {
            field: 'amount_cash',
            headerName: 'Cash',
            valueFormatter: (params) =>
                formatNumber(params.value, 0),
            cellClassName: 'grid-column-group-a first',
        },
        {
            field: 'amount_basis_only',
            headerName: 'Basis',
            valueFormatter: (params) =>
                formatNumber(params.value, 0),
            cellClassName: 'grid-column-group-a',
        },
        {
            field: 'amount_hta',
            headerName: 'HTA',
            valueFormatter: (params) =>
                formatNumber(params.value, 0),
            cellClassName: 'grid-column-group-a',
        },


        {
            field: 'unit_quantity_futures',
            headerName: 'Futures',
            valueFormatter: (params) =>
                formatNumber(params.value, 0),
            cellClassName: 'grid-column-group-b first',
        },
        {
            field: 'unit_quantity_long_put',
            headerName: 'Long Puts',
            valueFormatter: (params) =>
                formatNumber(params.value, 0),
            cellClassName: 'grid-column-group-b',
        },
        {
            field: 'unit_quantity_otc',
            headerName: 'OTC',
            valueFormatter: (params) =>
                formatNumber(params.value, 0),
            cellClassName: 'grid-column-group-b',
        },

        {
            field: 'unit_quantity_long_call',
            headerName: 'Long Calls',
            valueFormatter: (params) =>
                formatNumber(params.value, 0),
        },

        {
            field: 'icon',
            headerName: 'Action',
            width: 65,
            renderCell: (params) => (
                params.row.id !== 'total' && (
                    <IconButton onClick={() => navigate(`/marketing/position_summary/${params?.row.producer_token}/${currentYear}/${params?.row?.commodity_name}`)}>
                        <EastOutlinedIcon />
                    </IconButton>
                )
            ),
            flex: 1
        },
    ], [navigate]);

    // Add total row to the grid
    const rowsWithTotal = [];
    // useMemo(() => [
    //     ...allPositions,
    //     {
    //         id: 'total',
    //         commodity_name: 'Total',
    //         producer_name: 'Total',
    //         amount: formatNumber(totalAmount),
    //         percentage_of_production: formatNumber(totalProd),
    //         price: formatNumber(totalPrice),
    //         revenue: formatNumber(totalRevenue),
    //         revenue_per_acre: '',
    //     },
    // ], [allPositions, totalAmount, totalPrice, totalRevenue, totalProd]);

    // Helper function for rendering pop-up state buttons
    const renderPopupButton = useCallback((label, state, items, handleSelect) => (
        <PopupState variant="popover" popupId={`popup-${label}`}>
            {(popupState) => (
                <>
                    <Button variant="outlined" {...bindTrigger(popupState)}>{label} <ArrowDropDownOutlined /></Button>
                    <Menu {...bindMenu(popupState)}>
                        {items?.map((item, index) => (
                            <MenuItem key={index} onClick={() => {
                                handleSelect(item);
                                popupState.close();
                            }}>
                                {item}
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            )}
        </PopupState>
    ), []);

    return (
        <>
            <WorkspaceHeader
                title='Position Summary - Broker'
                breadCrumbs={[{ title: 'Broker', icon: <MarketingIcon /> }]}
            />
            <Grid container>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} className='headerWithMenuButton marketing-position'>
                        <AgentAutoComplete brokers={brokers} selectedBroker={selectedBroker} onChange={handleChangeAgentToken} />

                        {renderPopupButton(currentYear, "Year", getBudgetYears(), setCurrentYear)}

                        <StatefulControlledTextField
                            label="Search"
                            name="search_producer_name"
                            isRequired={false}
                            defaultValue={filter?.search_producer_name || ''}
                            onChange={filterHandleChangeState}
                            InputLabelProps={{ shrink: true }}
                        />

                        {isLoadingCurrent && <CircularProgress />}
                    </Stack>
                </Grid>
            </Grid>

            <TilleyDataGrid 
                rows={filteredReportData || []}
                columns={columns}
                pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                defaultPageSize={isMobile ? 5 : 15}
                gridKey="risk-summary-broker"
                getRowId={(row) => row.producer_token + "/" + (row?.commodity_name || "commodity")}
            />
        </>
    );
}
