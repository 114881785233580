const _percentageFormatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    signDisplay: "exceptZero",
});
const _priceFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencySign: "accounting",
    maximumFractionDigits: 0
});
const _priceFormatter2 = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencySign: "accounting",
    maximumFractionDigits: 2
});


const _priceFormatter_2 = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencySign: "accounting",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
});
const _priceFormatter_4 = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencySign: "accounting",
    maximumFractionDigits: 4,
    minimumFractionDigits: 4
});

export const formatPrice = (value, places) => {
    if (places == 2) {
        //return value?.toFixed(0);
        return _priceFormatter_2.format(value || 0)?.replace("$", "$ ");
    } else if (places == 4) {
        //return value?.toFixed(0);
        return _priceFormatter_4.format(value || 0)?.replace("$", "$ ");
    } else {
        //return value?.toFixed(0);
        return _priceFormatter.format(value || 0)?.replace("$", "$ ");
    }
}

export const formatPrice2 = (value) => {
    //return value?.toFixed(0);
    return _priceFormatter2.format(value || 0);
}

export const formatFixed1 = (value) => {
    const places = 1;
    value = ((value && Number(value)?.toFixed && Number(value)?.toFixed(places || 0)) || 0);
    return value;
}

export const formatFixed2 = (value) => {
    const places = 2;
    value = ((value && Number(value)?.toFixed && Number(value)?.toFixed(places || 0)) || 0);
    return value;
}

export const formatNumber = (value, places) => {
    // return ((value && Number(value)?.toFixed && Number(value)?.toFixed(places || 0)) || 0);

    value = ((value && Number(value)?.toFixed && Number(value)?.toFixed(places || 0)) || 0);
    const _numberFormatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: places || 0 });
    return _numberFormatter.format(value);
}

export const formatBase64Guid = (guid) => {
    // Remove dashes from the GUID
    const cleanGuid = guid.replace(/-/g, '');
    // Convert hex to a byte array
    const byteArray = new Uint8Array(cleanGuid.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));

    // Convert byte array to Base64
    let base64 = btoa(String.fromCharCode(...byteArray));
    // Modify Base64 to URL-safe and trim the trailing "==" padding
    base64 = base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

    return base64;
}

Math.round_ex = function (number, places) {
    //return +(Math.round(number + "e+" + places) + "e-" + places);
    return Number(number.toFixed(places || 0));
}

export const formatPercentage = (value, places) => {
    return formatNumber((value || 0) * 100, (places == null ? 2 : places)) + " %";
}

export const formatDate = (date) => {
    // Use toLocaleDateString with options weekday/month/day/year
    // Example: "10/14/2021"
    return (new Date(date)).toLocaleDateString('en-us', { month: 'numeric', day: 'numeric', year: 'numeric' });
}

export const formatDateLong = (date) => {
    // Example: "Tue, Oct 14, 2021"
    return (new Date(date)).toLocaleDateString('en-us', { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' });
}

export const formatDateContext = (date) => {
    if (!date) {
        return null;
    }

    // If it was a few seconds ago, show "Just now"
    // If it was a few minutes ago, show "X minutes ago"
    // If it was today, say "Today"
    // Else, show the date
    const now = new Date();
    const inputDate = new Date(date);
    const diff = now - inputDate;
    const diffMinutes = Math.floor(diff / 60000);
    if (diffMinutes <= 1) {
        return "Just now";
    } else if (diffMinutes < 60) {
        return diffMinutes + " minutes ago";
    } else if (now.toDateString() === inputDate.toDateString()) {
        return "Today";
    } else {
        return inputDate.toLocaleDateString('en-us');
    }
}

export const formatDateTime = (date) => {
    // For today's date, show the time; otherwise, show the date
    const today = new Date();
    const inputDate = new Date(date);
    if (today.toDateString() === inputDate.toDateString()) {
        return inputDate.toLocaleTimeString('en-us');
    } else {
        return inputDate.toLocaleDateString('en-us');
    }
}

// ---

export const formatProducerName = (row) => {
    if (!row) return null;

    let nameLabel = (row?.producer_business_name || row?.business_name) ?
        (row.producer_business_name || row.business_name)
        : (row?.producer_first_name || row?.first_name || "") + " " + (row?.producer_middle_name || row?.middle_name || "") + " " + (row.producer_last_name || row.last_name || "");
    if (row?.account_number) {
        nameLabel += " (" + row.account_number + ")";
    }
    return nameLabel;
}

// ---

export const PRACTICE_NAME_SHORT_IRRIGATED = "IRR";
export const PRACTICE_NAME_SHORT_NON_IRRIGATED = "NI";
export const PRACTICE_NAME_SHORT_NON_IRRIGATED_SKIP_ROW = "NI/Skip";

export const formatPracticeName = (value) => {
    if (value?.toLowerCase()?.includes("skip row")) {
        return PRACTICE_NAME_SHORT_NON_IRRIGATED_SKIP_ROW;
    } else if (value?.toLowerCase()?.includes("non-irrigated")) {
        return PRACTICE_NAME_SHORT_NON_IRRIGATED;
    } else if (value?.toLowerCase()?.includes("irrigated")) {
        return PRACTICE_NAME_SHORT_IRRIGATED;
    } else {
        return "";
    }
}