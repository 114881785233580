import { useDialog } from '@datanac/datanac-mui-toolkit/dist/hooks'
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogActions, Grid, IconButton, Menu, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material'
import { AppContext } from 'App'
import { useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { Auth } from "aws-amplify";
import { useSnackbar } from 'notistack'
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader'
import { MarketingIcon } from 'components/Icons/Icons'
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar';
import EnableMfaTotp from 'EnableMfaTotp';
import CloseOutlined from '@mui/icons-material/CloseOutlined'
import { NO_MFA, SMS_MFA, SOFTWARE_TOKEN_MFA } from 'core/AuthHelper';

export default function UserPreferencesWorkspace() {
    const { globalState, globalDispatch } = useContext(AppContext);

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });

    const { enqueueSnackbar } = useSnackbar();

    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        loadCurrentUser();
    }, []);

    const loadCurrentUser = async () => {
        try {
            let curUser = await Auth.currentAuthenticatedUser();
            setCurrentUser(curUser);
        } catch (error) {
            console.error("Error loading current user:", error);
        }
    };

    // ---
    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const handleOnClick = () => {
        confirmDialogActions.confirm({
            title: "TOTP Configuration",
            prompt: "Do you wish to change your one-time password token?",
            promptDetail: null,
            callback: () => {
                setEditingItem(true);
            }
        })
    }

    const [editingItem, setEditingItem] = useState(null);

    const closeDialog = () => {
        setEditingItem(false);
    }

    const handleTotpOnChange = () => {
        enqueueSnackbar("One-Time Password Token changed successfully", { variant: "info" });
        loadCurrentUser();
        closeDialog();
    }

    const getMFAStatus = () => {
        if (currentUser?.preferredMFA === SOFTWARE_TOKEN_MFA) {
            return "One-Time Password";
        } else if (currentUser?.preferredMFA === SMS_MFA) {
            return "SMS";
        } else if (currentUser?.preferredMFA === NO_MFA) {
            return "Not enabled";
        } else {
            return "MFA status unknown";
        }
    };

    return (
        <>
            <WorkspaceHeader
                title={currentUser?.username}
                breadCrumbs={[{
                    title: 'Change One-Time Password Token',
                    icon: <MarketingIcon />
                }]}
            />

            <Grid container>
                <Grid item xs={12} lg={2}>
                    <Typography variant='h1'>
                        MFA Status
                    </Typography>

                    <Typography variant="body2" component="h2">
                        {getMFAStatus()}
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={10}>
                    <Stack direction="row" spacing={2}>
                        <Button variant="contained" onClick={handleOnClick}>
                            Change One-Time Password Token
                        </Button>

                    </Stack>
                </Grid>
            </Grid>

            <Dialog open={Boolean(editingItem != null) && editingItem} className='changeMFADialog'>
                <DialogContent>
                    <EnableMfaTotp handleLogoutClick={''} type={'userProfile'} onClose={closeDialog} onChange={handleTotpOnChange} />
                </DialogContent>
                <DialogActions>
                    <Button sx={{ mr: 1 }} variant="outlined"
                        onClick={() => { setEditingItem(false) }}>
                        <CloseOutlined sx={{ mr: 1 }} />
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {confirmDialogComponent}
        </>
    )
}