import React, { createContext } from "react";

const DataGridContext = createContext();

export const DataGridProvider = ({ children }) => {
    const getGridState = () => {
        return JSON.parse(localStorage.getItem("TilleyDataGridStates")) || {};
    };

    const setGridState = (gridKey, state) => {
        const existingState = getGridState();
        const updatedState = {
            ...existingState,
            [gridKey]: { ...existingState[gridKey], ...state },
        };
        localStorage.setItem("TilleyDataGridStates", JSON.stringify(updatedState));
    };

    return (
        <DataGridContext.Provider value={{ getGridState, setGridState }}>
            {children}
        </DataGridContext.Provider>
    );
};

export default DataGridContext;