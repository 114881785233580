import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, Stack, Typography } from "@mui/material";
import { _sessionManager, UsersApiHelper } from 'api/ApiHelper';
import { AppContext } from "App";
import { StatefulControlledTextField } from 'components/Core/StatefulControlledTextField';
import { useFormStatus } from "mui-toolkit/useFormStatus";
import { useSnackbar } from 'notistack';
import { Fragment, useContext, useEffect, useState } from "react";
import { ApiHelper } from '@datanac/datanac-api-toolkit/dist/api-helper';

// ---

export const ClientAccountNumberDialog = ({
    onChange,
    onClose,
    data,
}) => {
    const { globalState } = useContext(AppContext);
    const { component: formStatusComponent, actions: formStatusActions, state: formStatusState } = useFormStatus();

    const [state, setState] = useState({});
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState(false);

    // ---

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const onSubmit = (e) => {
        onChange && onChange(state);
        onClose && onClose();
    }

    const [dataDictionary, setDataDictionary] = useState([]);
    useEffect(() => {
        setState({
            container: "rjo",
            object: "account_number",
            value: "",
            is_active: true
        });
    }, [data]);

    // ---

    const handleChangeState = async (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        if (state[event.target.name] != value) {
            formStatusActions.setIsFormDirty(true, false);

            const _newState = { ...state };
            _newState[event.target.name] = value;
            setState(_newState);
        }
    }

    const formatProducerName = () => {
        return (state?.business_name || state?.first_name) ? (state?.business_name ? state?.business_name : (state?.first_name + ' ' + state?.last_name)) : '';
    }

    // ---


    return (<>
        <DialogTitle>
            {formatProducerName(state)}
        </DialogTitle>

        <DialogContent>
            <Box sx={{ width: '100%' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Account Number"
                                name="value"
                                isRequired={true}
                                defaultValue={state?.value || ''}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </DialogContent>

        <DialogActions>
            <Stack direction="row-reverse" spacing={1} useFlexGap flexWrap="wrap" justifyContent='flex-end'>
                <Button variant='contained' onClick={onSubmit}>OK</Button>
                <Button variant='contained' color='secondary' autoFocus onClick={onClose}>Cancel</Button>
            </Stack>
        </DialogActions>
    </>);
}