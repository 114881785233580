import { getCurrentReinsuranceYear } from "@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper";
import { Box, Button, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Stack, useMediaQuery } from "@mui/material";
import { AppContext } from "App";
import DataFormSelect from 'components/Core/DataFormSelect';
import { StatefulControlledTextField } from "components/Core/StatefulControlledTextField";
import { useFormStatus } from "mui-toolkit/useFormStatus";
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from "react";

// ---
export const BuyerProfileEditDialog = ({
    buyerProfile,
    onClose,
    onChange,
}) => {
    const { globalState } = useContext(AppContext);
    const { component: formStatusComponent, actions: formStatusActions, state: formStatusState } = useFormStatus();

    const [state, setState] = useState({});
    const { enqueueSnackbar } = useSnackbar();

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const [isLoading, setIsLoading] = useState(false);
   
    useEffect(() => {
        // Load default state:
        if (buyerProfile) {
            const _buyerProfile = {
                financial_institution_name: '',
                city_name: null,
                state_name: null,
                ...buyerProfile,
            };
            setState(_buyerProfile);
        } else {
            setState({});
        }
    }, [buyerProfile]);

    const onSubmit = (e) => {
        if (!validateForm()) {
            return;
        }
        onChange && onChange(state);
        onClose && onClose();
    }

    // ---
    const handleChangeState = async (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        if (state[event.target.name] != value) {
            formStatusActions.setIsFormDirty(true, false);

            const _newState = { ...state };
            _newState[event.target.name] = value;
            setState(_newState);
        }
    }

    const validateForm = () => {
        const validationRules = [
            { field: 'financial_institution_name', message: 'Broker Name is required.' },
            { field: 'state_name', message: 'State is required.' },
            { field: 'city_name', message: 'City is required.' },
        ];

        for (const rule of validationRules) {
            if (!state?.[rule.field] && (rule.condition === undefined || rule.condition)) {
                enqueueSnackbar(rule.message, { variant: 'error' });
                return false;
            }
        }
        return true;
    };
    const formMaxWidthShort = '800px';

    return (<>
        <DialogTitle>
            Buyers Profile
        </DialogTitle>
        <DialogContent sx={{overflow: 'visible'}}>
            <Box sx={{ width: '100%', maxWidth: formMaxWidthShort }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Buyer Name"
                                name="financial_institution_name"
                                isRequired={false}
                                defaultValue={state?.financial_institution_name || ''}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="City"
                                name="city_name"
                                isRequired={true}
                                defaultValue={state?.city_name || ''}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} className="clientState">
                        <DataFormSelect
                            value={state?.state_name}
                            name="state_name"
                            onChange={handleChangeState}
                            objectName="State"
                            itemFilter={{ reinsurance_year: getCurrentReinsuranceYear() }}
                            itemGetKey={item => item.state_abbreviation}
                            itemGetLabel={item => item.state_abbreviation}
                            doFetch={true}
                            isLoading={isLoading}
                            isSmall={true}
                            multiple={false}
                            open={true}
                        />
                    </Grid>
                </Grid>
            </Box>
        </DialogContent>

        <DialogActions>
            <Stack direction="row-reverse" spacing={1} useFlexGap flexWrap="wrap" justifyContent='flex-end'>
                <Button variant='contained' onClick={onSubmit}>OK</Button>
                <Button variant='contained' color='secondary' autoFocus onClick={onClose}>Cancel</Button>
            </Stack>
        </DialogActions>
    </>);
}