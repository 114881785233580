import { ApiHelper } from '@datanac/datanac-api-toolkit/dist/api-helper';
import { getCurrentReinsuranceYear } from '@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper';
import { useDialog } from '@datanac/datanac-mui-toolkit/dist/hooks';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import { _sessionManager } from 'api/ApiHelper';
import { AppContext } from "App";
import DataFormSelect from 'components/Core/DataFormSelect';
import { MASK_NUMBER_UNFORMATTED, StatefulControlledTextField } from 'components/Core/StatefulControlledTextField';
import { useFormStatus } from "mui-toolkit/useFormStatus";
import { useSnackbar } from 'notistack';
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import BuyersGrid from './BuyersGrid';
import { ClientAccountNumberDialog } from './ClientAccountNumberDialog';
import CropProfileGrid from './CropProfileGrid';
import { MODULE_MARKETING } from 'components/Menu/NavigationMenu';
import { v4 } from 'uuid';

// ---

export const ClientDialog = ({
    onChange,
    onClose,
    member,
}) => {
    const { globalState } = useContext(AppContext);
    const { component: formStatusComponent, actions: formStatusActions, state: formStatusState } = useFormStatus();
    const [state, setState] = useState({});
    const { enqueueSnackbar } = useSnackbar();
    const { component: confirmDialogComponent, actions: confirmDialogActions } = useDialog();
    const [isLoading, setIsLoading] = useState(false);
    const [isBuyerModalOpen, setIsBuyerModalOpen] = useState(false);
    const [isCropProfileModalOpen, setIsCropProfileModalOpen] = useState(false);

    const handleAddBuyer = () => {
        setIsBuyerModalOpen(true); // Open the buyer modal
    };
    const handleCloseBuyerModal = () => {
        setIsBuyerModalOpen(false); // Reset to false when buyer modal is closed
    };

    const handleAddCropProfile = () => {
        setIsCropProfileModalOpen(true); // Open the crop profile modal
    };

    const handleCloseCropProfileModal = () => {
        setIsCropProfileModalOpen(false); // Reset to false when crop profile modal is closed
    };

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    const handleRowDelete_Account = useCallback(async (row) => {
        confirmDialogActions.confirm({
            title: "Delete this entry?",
            prompt: "Are you sure you want to delete this entry?",
            promptDetail: null,
            callback: () => {
                handleRowDelete_Account_Intern(row);
            }
        })
    }, [confirmDialogActions]);

    const onSubmit = (e) => {
        onChange && onChange(state);
        onClose && onClose();
    }

    const [editingItem, setEditingItem] = useState(null);


    const _loadDataDictionary = async () => {
        try {
            if (member?.producer_token) {
                const _filter = {
                    producer_token: member?.producer_token,
                    is_active: true
                };

                const api = new ApiHelper({
                    baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
                    config: {},
                    sessionManager: _sessionManager,
                    // No caching:
                    _instance: null
                });
                const _dataDictionary = await api.callPost("DataDictionary/Search", _filter, globalState);

                setDataDictionary(_dataDictionary);
            }
        } catch (err) {
            handleError(err);
        }
    }

    const [dataDictionary, setDataDictionary] = useState([]);
    useEffect(() => {
        setState(member);
        _loadDataDictionary();
    }, [member]);

    // ---

    const handleChangeState = async (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        if (state[event.target.name] != value) {
            formStatusActions.setIsFormDirty(true, false);

            const _newState = { ...state };
            _newState[event.target.name] = value;
            setState(_newState);
        }
    }

    const formatProducerName = () => {
        return (state?.business_name || state?.first_name) ? (state?.business_name ? state?.business_name : (state?.first_name + ' ' + state?.last_name)) : '';
    }

    const handleChangeDataDictionary = async (account) => {
        try {
            const _newState = { ...account };
            const SECTION_TILLEY = "Tilley";
            // Create ID if not exists
            _newState['id'] = _newState['id'] || v4();
            _newState['producer_token'] = member?.producer_token;
            _newState['section'] = SECTION_TILLEY;
            _newState['module'] = MODULE_MARKETING;
            _newState['object'] = account.object;
            _newState['container'] = account.container;
            _newState['value'] = account.value;

            const api = new ApiHelper({
                baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
                config: {},
                sessionManager: _sessionManager,
                // No caching:
                _instance: null
            });
            await api.callPost("marketing/DataDictionary", _newState, globalState);

            _loadDataDictionary();
        } catch (err) {
            handleError(err);
        }
    }

    const handleRowDelete_Account_Intern = async (row) => {
        const _newRow = { ...row };
        _newRow.is_active = false;

        // HandleChange will reload data
        handleChangeDataDictionary(_newRow);
    }

    // ---


    return (<>
        <DialogTitle>
            {formatProducerName(state)}
        </DialogTitle>

        <DialogContent>
            <Box sx={{ width: '100%' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="First Name"
                                name="first_name"
                                isRequired={true}
                                defaultValue={state?.first_name || ''}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Last Name"
                                name="last_name"
                                isRequired={true}
                                defaultValue={state?.last_name || ''}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="Business Name"
                                name="business_name"
                                isRequired={true}
                                defaultValue={state?.business_name || ''}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth={true}>
                            <StatefulControlledTextField
                                label="City"
                                name="city"
                                isRequired={true}
                                defaultValue={state?.city || ''}
                                onChange={handleChangeState}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} className="clientState">
                        <DataFormSelect
                            value={state?.state_abbreviation}
                            name="state_abbreviation"
                            onChange={handleChangeState}
                            objectName="State"
                            itemFilter={{ reinsurance_year: getCurrentReinsuranceYear() }}
                            itemGetKey={item => item.state_abbreviation}
                            itemGetLabel={item => item.state_abbreviation}
                            doFetch={true}
                            isLoading={isLoading}
                            isSmall={true}
                            multiple={false}
                        />
                    </Grid>
                </Grid>

                <Divider />

                <Typography variant='h6' sx={{ mb: 2 }}>Accounts</Typography>

                <Grid container spacing={2}>
                    {/* {Boolean(member?.producer_token) && <> */}
                    {(dataDictionary || [])
                        ?.map((data, index) => <Fragment key={index}>
                            {data.object == 'office_code' &&
                                <Grid item xs={12} md={6} key={index}>
                                    <FormControl fullWidth={true}>
                                        <StatefulControlledTextField
                                            label={"Office Code (" + data?.container + ")"}
                                            name="office_code"
                                            isRequired={true}
                                            defaultValue={data?.value || ''}
                                            onChange={handleChangeState}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </FormControl>
                                </Grid>
                            }

                            {data?.object == 'account_number' &&
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth={true}>
                                        <StatefulControlledTextField
                                            label={"Account Number (" + data?.container + ")"}
                                            name="account_number"
                                            isRequired={true}
                                            defaultValue={data?.value || ''}
                                            variant="outlined"
                                            onChange={handleChangeState}
                                            InputLabelProps={{ shrink: true }}
                                            endAdornment={
                                                <InputAdornment position="end" className='delete'>
                                                    <IconButton onClick={() => handleRowDelete_Account(data)} edge="end" color="secondary">
                                                        <DeleteOutlined />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            }
                        </Fragment>
                        )}
                    {/* </>} */}

                    <Grid item xs={12} md={6} style={{ paddingTop: "8px" }}>
                        <Typography variant='body2' sx={{ mb: 1 }}>Add Account</Typography>
                        <Button fullWidth sx={{ borderRadius: '4pt', height: '53px', mt: 'auto' }} variant='text' color='secondary'
                            onClick={() => setEditingItem({})}>
                            <AddCircleOutline />
                        </Button>
                    </Grid>
                </Grid>

                <Divider />
                        
                {Boolean(member?.producer_token) && <>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ mb: 2 }}
                    >
                        <Typography variant='h6' sx={{ mb: 1 }}>Crop Profiles</Typography>
                        <Button variant="outlined" onClick={handleAddCropProfile}>
                            <AddCircleOutline/> Add Crop Profile
                        </Button>
                    </Box>
                    <CropProfileGrid producer_token={member?.producer_token} onAddCropProfile={isCropProfileModalOpen} onClose={handleCloseCropProfileModal} />

                    <Divider />

                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ mb: 2 }}
                    >
                        <Typography variant="h6">Buyers</Typography>
                        <Button variant="outlined" onClick={handleAddBuyer}>
                            <AddCircleOutline/> Add Buyer
                        </Button>
                    </Box>
                    <BuyersGrid
                        producer_token={member?.producer_token}
                        onAddBuyer={isBuyerModalOpen} // Pass state to control modal visibility
                        onClose={handleCloseBuyerModal}
                    />
                </>}
            </Box>
        </DialogContent>

        <DialogActions>
            <Stack direction="row-reverse" spacing={1} useFlexGap flexWrap="wrap" justifyContent='flex-end'>
                <Button variant='contained' onClick={onSubmit}>OK</Button>
                <Button variant='contained' color='secondary' autoFocus onClick={onClose}>Cancel</Button>
            </Stack>
        </DialogActions>

        <Dialog open={Boolean(editingItem != null)}>
            <ClientAccountNumberDialog data={editingItem}
                onChange={handleChangeDataDictionary} onClose={() => setEditingItem(null)} />
        </Dialog>

        {confirmDialogComponent}
    </>);
}