import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, Stack, TextField, useMediaQuery } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { _sessionManager, ApiHelper, UsersApiHelper } from "api/ApiHelper";
import { AppContext } from "App";
import { getUnitOfMeasureAbbreviation } from "components/Budget/BudgetService";
import { BUDGET_STATUS_ACTIVE } from "components/Budget/helpers";
import { MASK_NUMERIC, StatefulControlledTextField } from "components/Core/StatefulControlledTextField";
import dayjs from 'dayjs';
import { useFormStatus } from "mui-toolkit/useFormStatus";
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";
import { ApiHelper as ToolkitApiHelper } from "@datanac/datanac-api-toolkit/dist/api-helper";
import { isFieldDisabled, SALE_CATEGORIES, SALE_CATEGORY_CASH } from "./SalesHelper";
import DataFormSelect from "components/Core/DataFormSelect";
import { getCurrentReinsuranceYear } from "@datanac/datanac-api-toolkit/dist/utility/InsurancePlanHelper";

// ---
export const SalesEditDialog = ({
    // Agent action: Override producer_token. Permissions enforced by API.
    producer_token,

    onChange,
    onClose,
    sale,
    crop_year,
}) => {
    const { globalState } = useContext(AppContext);
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });
    const { component: formStatusComponent, actions: formStatusActions, state: formStatusState } = useFormStatus();
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState(sale || {
        is_active: true,
        crop_year: crop_year,
        category: SALE_CATEGORY_CASH
    });

    useEffect(() => {
        setState({
            ...state,
            crop_year: crop_year,
        });
    }, [crop_year])

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    // ---

    const onSubmit = (e) => {
        if (!validateForm()) {
            return;
        }
        if (!state?.id) {
            state.producer_token = producer_token;// Override producer_token. Permissions enforced by API.

            state.id = v4();
            state.crop_year = crop_year,
                state.is_active = true;
            state.trade_date = state.trade_date || new Date();
            state.trade_timestamp = state.trade_timestamp || new Date();
            state.revenue = state?.revenue || 0;
        }

        onChange && onChange(state);
        onClose && onClose();
    }

    useEffect(() => {
        setState(sale);
    }, [sale]);

    // ---

    const loadBuyers = async () => {
        try {
            const _buyerFilter = {
                producer_token: producer_token,// Override producer_token. Permissions enforced by API.
            };
            // This is agent function. Do not specify producer_token or you MAY get impersonation conflict.
            const _apiConfig = {
                agent_token: globalState?.agent_token,
            };

            const _buyers = await UsersApiHelper.users_selectObject("Buyer", _buyerFilter, _apiConfig);
            const _buyerNames = _buyers.map(buyer => buyer?.financial_institution_name + " - " + buyer?.city_name + ", " + buyer?.state_name);
            setBuyers(_buyerNames);
        } catch (err) {
            handleError(err);
        }
    };

    // const [selectedInstitution, setSelectedInstitution] = useState(state?.financial_institution_name || '');
    const [buyers, setBuyers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isCreateBuyerDialogOpen, setIsCreateBuyerDialogOpen] = useState(false);
    useEffect(() => {
        loadBuyers();
    }, [globalState?.producer_token, globalState?.agent_token, globalState?.user_role]);

    const handleCreateBuyer = async () => {
        if (state?.buyer_financial_institution_name && state?.buyer_city_name && state?.buyer_state_name) {
            // Update: Create new Buyer
            const _newBuyer = {
                id: v4(),
                producer_token: producer_token,// Override producer_token. Permissions enforced by API.
                financial_institution_name: state?.buyer_financial_institution_name,
                city_name: state?.buyer_city_name,
                state_name: state?.buyer_state_name,
                country_code: "",
                is_active: true,
            };
            const api = new ToolkitApiHelper({
                baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
                config: {},
                sessionManager: _sessionManager,
                // No caching:
                _instance: null
            });
            // This is agent function. Do not specify producer_token or you MAY get impersonation conflict.
            const _apiConfig = {
                agent_token: globalState?.agent_token,
            };
            await api.callPost("marketing/Buyer", _newBuyer, _apiConfig);


            setIsCreateBuyerDialogOpen(false);
            const _name = _newBuyer?.financial_institution_name + " - " + _newBuyer?.city_name + ", " + _newBuyer?.state_name;
            handleChangeState({ target: { name: 'financial_institution_name', value: _name } });
            loadBuyers();
        } else {
            enqueueSnackbar("Please ensure all required fields are filled and try again.", { variant: 'error' });
        }
    }

    // ---

    const [futuresSymbols, setFuturesSymbols] = useState([]);
    const [isLoadingFuturesSymbols, setIsLoadingFuturesSymbols] = useState(false);

    const selectedFuturesSymbol = useMemo(() =>
        futuresSymbols?.find(s => s?.symbol == state?.symbol) || null,
        [futuresSymbols, state?.symbol]);

    const [unitOfMeasureAbbreviation, setUnitOfMeasureAbbreviation] = useState(null);
    useEffect(() => {
        const _loadUnitOfMeasureAbbreviation = async () => {
            try {
                const _currentYear = state?.crop_year;

                // Resolve some props from Budget
                // ANY budget for this Crop will provide the xref information.
                const _budgetFilter = {
                    commodity_name: state?.commodity_name,
                    year: _currentYear,

                    status: BUDGET_STATUS_ACTIVE,
                    is_active: true,
                }
                const _budgets = await UsersApiHelper.selectBudgetCountyPractices(_budgetFilter, globalState);

                const _unitOfMeasureAbbreviationFilter = {};
                if (_budgets?.length) {
                    _unitOfMeasureAbbreviationFilter.reinsurance_year = _currentYear;

                    _unitOfMeasureAbbreviationFilter.state_name = _budgets[0]?.state_name;
                    _unitOfMeasureAbbreviationFilter.county_name = _budgets[0]?.county_name;

                    _unitOfMeasureAbbreviationFilter.commodity_name = _budgets[0]?.commodity_name;
                    _unitOfMeasureAbbreviationFilter.type_name = _budgets[0]?.type_name;
                    _unitOfMeasureAbbreviationFilter.practice_name = _budgets[0]?.practice_name;

                    const _unitOfMeasureAbbreviation = await getUnitOfMeasureAbbreviation(_unitOfMeasureAbbreviationFilter);
                    setUnitOfMeasureAbbreviation(_unitOfMeasureAbbreviation?.trim()?.toLowerCase());
                } else {
                    setUnitOfMeasureAbbreviation(null);
                }
            } catch (err) {
                handleError(err);
            }
        }

        if (state?.commodity_name) {
            _loadUnitOfMeasureAbbreviation();
        } else {
            setUnitOfMeasureAbbreviation(null);
        }
    }, [state?.commodity_name]);

    useEffect(() => {
        const _loadFuturesSymbols = async () => {
            try {
                const _currentYear = state?.crop_year;
                const _currentMonth = dayjs().month() + 1;

                // Resolve some props from Budget
                // ANY budget for this Crop will provide the xref information.
                const _budgetFilter = {
                    commodity_name: state?.commodity_name,
                    year: _currentYear,

                    status: BUDGET_STATUS_ACTIVE,
                    is_active: true,
                }
                const _budgets = await UsersApiHelper.selectBudgetCountyPractices(_budgetFilter, globalState);

                const _symbolFilter = {
                    // year: state?.crop_year,
                    commodity_name: state?.commodity_name
                };
                if (_budgets?.length) {
                    _symbolFilter.reinsurance_year = _currentYear;

                    _symbolFilter.state_name = _budgets[0]?.state_name;
                    _symbolFilter.county_name = _budgets[0]?.county_name;

                    _symbolFilter.commodity_name = _budgets[0]?.commodity_name + " ";
                    _symbolFilter.type_name = _budgets[0]?.type_name;
                    _symbolFilter.practice_name = _budgets[0]?.practice_name;
                }
                const _futuresSymbols = await ApiHelper.callSmociObject("data/DATANAC", "Markets", "FuturesSymbol", "Search", _symbolFilter, globalState)


                const _orderedSymbols = _futuresSymbols
                    .filter(symbol =>
                        // Future year Or current year, not expired:
                        symbol?.year >= _currentYear - 3
                    )
                    .map(symbol => ({
                        ...symbol,
                        is_current_year: symbol?.year == _currentYear && symbol?.month_number >= _currentMonth,
                        is_expired: (symbol?.year < _currentYear || (symbol?.year == _currentYear && symbol?.month_number < _currentMonth)),
                    }));
                _orderedSymbols
                    .sort((a, b) => {
                        if (a?.is_expired && !b?.is_expired) {
                            return 1;
                        }
                        if (!a?.is_expired && b?.is_expired) {
                            return -1;
                        }

                        if (a?.is_current_year && !b?.is_current_year) {
                            return -1;
                        }
                        if (!a?.is_current_year && b?.is_current_year) {
                            return 1;
                        }
                        if (a?.is_current_year && b?.is_current_year) {
                            return a?.month_number - b?.month_number;
                        }
                        return a?.year - b?.year;
                    });

                const _autocompleteSymbols = _orderedSymbols.map(symbol => ({
                    label: symbol?.symbol + " - " + symbol?.month_name + " " + symbol?.year + " " + symbol?.commodity_name,
                    id: symbol?.symbol,
                    symbol: symbol?.symbol,
                    month_name: symbol?.month_name,
                    year: symbol?.year,
                    commodity_name: symbol?.commodity_name,
                }));

                setFuturesSymbols(_autocompleteSymbols);
            } catch (err) {
                handleError(err);
                setFuturesSymbols([]);
            }
        };

        if (state?.commodity_name) {
            _loadFuturesSymbols();
        }
    }, [globalState?.producer_token, globalState?.agent_token, state?.commodity_name])

    // ---

    const handleChangeStateSelect = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        formStatusActions.setIsFormDirty(true, false);

        if (state[event?.target?.name] != value) {
            formStatusActions.setIsFormDirty(true, false);

            const _newState = { ...state };
            _newState[event?.target?.name] = value;

            setState(_newState);
        }
    }

    const handleChangeState = async (event, value) => {
        if (!value && event.target.value) {
            value = event.target.value
        }

        if (state[event?.target?.name] != value) {
            formStatusActions.setIsFormDirty(true, false);

            const _newState = { ...state };
            _newState[event?.target?.name] = value;
            setState(_newState);
        }
    }

    // ---

    useEffect(() => {
        if ((state?.delivery_period_start && !state?.delivery_period_end)
            || (state?.delivery_period_start && state?.delivery_period_end && state?.delivery_period_start > state?.delivery_period_end)) {
            const _newState = { ...state };
            // Set to end of month:
            _newState.delivery_period_end = dayjs(state?.delivery_period_start).endOf('month').startOf('day').toDate();
            setState(_newState);
        }
    }, [state?.delivery_period_start, state?.delivery_period_end]);

    useEffect(() => {
        const _newState = { ...state };

        isFieldDisabled(state, 'amount') && (_newState.amount = null);
        isFieldDisabled(state, 'price') && (_newState.price = null);
        isFieldDisabled(state, 'symbol') && (_newState.symbol = null);
        isFieldDisabled(state, 'basis') && (_newState.basis = null);

        setState(_newState);
    }, [state?.category]);

    // ---

    const formMaxWidthShort = '800px';


    const validateForm = () => {
        const validationRules = [
            { field: 'commodity_name', message: 'Commodity is required.' },
            { field: 'category', message: 'Type is required.' },
            { field: 'amount', message: 'Amount is required.', condition: !isFieldDisabled(state, 'amount') },
            { field: 'price', message: 'Futures price is required.', condition: !isFieldDisabled(state, 'price') },
            { field: 'trade_date', message: 'Trade Date is required.' },
            { field: 'financial_institution_name', message: 'Buyer is required.' },
            // Not required
            // { field: 'delivery_period_start', message: 'Delivery period start date is required.' },
            // { field: 'delivery_period_end', message: 'Delivery period end is required.' },
            // { field: 'notes', message: 'Notes are required.' }
        ];

        for (const rule of validationRules) {
            if (!state?.[rule.field] && (rule.condition === undefined || rule.condition)) {
                enqueueSnackbar(rule.message, { variant: 'error' });
                return false;
            }
        }
        return true;
    };

    return (
        <>
            <DialogTitle>{state?.financial_institution_name ? state?.financial_institution_name : "Add Sale"}</DialogTitle>
            <DialogContent>
                <Box sx={{ width: '100%', maxWidth: formMaxWidthShort }}>
                    <Grid container spacing={2}>

                        <Grid item xs={12} md={6} key={name}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={state?.category || null}
                                    onChange={(event, newValue) => {
                                        handleChangeStateSelect({ target: { name: 'category', value: newValue } });
                                    }}
                                    autoSelect
                                    autoHighlight
                                    options={SALE_CATEGORIES}
                                    getOptionLabel={(option) => option || ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Type"
                                            name="category"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DataFormSelect
                                value={state?.commodity_name || ''}
                                name="commodity_name"
                                isRequired={true}
                                onChange={handleChangeStateSelect}
                                objectName="Commodity"
                                itemFilter={{ reinsurance_year: getCurrentReinsuranceYear() }}
                                itemGetKey={(item) => item.commodity_name}
                                itemGetLabel={(item) => item.commodity_name}
                                doFetch={true}
                                isLoading={false}
                                multiple={false}
                                limitTags={isSmall ? 2 : 3}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <StatefulControlledTextField
                                    label={'Amount ' + (unitOfMeasureAbbreviation ? "(" + unitOfMeasureAbbreviation + ")" : "")}
                                    name={'amount'}
                                    required
                                    defaultValue={state?.amount || ""}
                                    onChange={handleChangeState}
                                    InputLabelProps={{ shrink: true }}
                                    disabled={isFieldDisabled(state, 'amount')}
                                    maskType={MASK_NUMERIC}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <StatefulControlledTextField
                                    label={'Futures'}
                                    name={'price'}
                                    required
                                    defaultValue={state?.price || ''}
                                    onChange={handleChangeState}
                                    InputLabelProps={{ shrink: true }}
                                    disabled={isFieldDisabled(state, 'price')}
                                    maskType={MASK_NUMERIC}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    name="symbol"
                                    options={futuresSymbols || []}
                                    multiple={false}
                                    value={selectedFuturesSymbol || null}
                                    getOptionLabel={(option) => option?.label || ""}
                                    disabled={isFieldDisabled(state, 'symbol')}
                                    onChange={(event, value) => {
                                        handleChangeState({ target: { name: 'symbol', value: value?.symbol } });
                                    }}
                                    autoSelect
                                    autoHighlight
                                    renderInput={(params) => (
                                        <TextField
                                            name="symbol"
                                            {...params}
                                            label="Symbol"
                                            required
                                            onChange={handleChangeState}
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                    renderOption={(props, option) => {
                                        const { key, ...optionProps } = props;
                                        return (
                                            <li key={key} {...optionProps}>
                                                <Stack direction="row" spacing={1} key={key} sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                                    <Box sx={{ textAlign: "center", width: "60pt" }}>{option?.symbol}</Box>
                                                    <Box sx={{ alignContent: "center" }}>-</Box>
                                                    <Box sx={{ textAlign: "center", width: "60pt" }}>{option?.month_name?.toUpperCase()} {(String(option?.year) + "  ")?.substring(2, 4)}</Box>
                                                    <Box sx={{ alignContent: "center" }}>-</Box>
                                                    <Box sx={{ alignContent: "center" }}>{option?.commodity_name}</Box>
                                                </Stack>
                                            </li>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <StatefulControlledTextField
                                    label={'Basis'}
                                    name={'basis'}
                                    required
                                    defaultValue={state?.basis || '0'}
                                    onChange={handleChangeState}
                                    InputLabelProps={{ shrink: true }}
                                    disabled={isFieldDisabled(state, 'basis')}
                                    maskType={MASK_NUMERIC}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth className="trade_date">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        className="trade_date_picker"
                                        name="trade_date"
                                        label="Trade Date"
                                        format="MM/DD/YYYY"
                                        value={state?.trade_date ? dayjs(state?.trade_date) : null}
                                        onChange={(val) => handleChangeState({ target: { name: 'trade_date', value: val } })}
                                        slots={{
                                            textField: TextField,
                                        }}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                onKeyDown: (e) => {
                                                    if (e.key === 'Tab') {
                                                        e.stopPropagation(); // Prevent focus from moving to the calendar icon
                                                    }
                                                },
                                            },
                                            openPickerButton: {
                                                tabIndex: -1, // Skip the calendar icon in the tab order
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    name="financial_institution_name"
                                    options={buyers || []}
                                    value={state?.financial_institution_name || null}
                                    getOptionLabel={(option) => option}
                                    onChange={(event, value) => {
                                        // setSelectedInstitution(value);
                                        handleChangeState({ target: { name: 'financial_institution_name', value } });
                                    }}
                                    autoSelect
                                    autoHighlight
                                    renderInput={(params) => (
                                        <TextField
                                            name="financial_institution_name"
                                            {...params}
                                            label="Buyer"
                                            required
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                    PaperComponent={({ children }) => {
                                        return (
                                            <Paper>
                                                {children}
                                                <Button
                                                    color="primary"
                                                    variant="filled"
                                                    fullWidth
                                                    sx={{ justifyContent: "flex-start", pl: 2 }}
                                                    onMouseDown={() => {
                                                        setIsCreateBuyerDialogOpen(true);
                                                    }}
                                                >
                                                    + Add New
                                                </Button>
                                            </Paper>
                                        );
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth className="delivery_period_start">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        className="delivery_period_start"
                                        name="delivery_period_start"
                                        label="Delivery period start"
                                        format="MM/DD/YYYY"
                                        value={(state?.delivery_period_start && dayjs(state?.delivery_period_start)) || null}
                                        onChange={val => handleChangeState({ target: { name: 'delivery_period_start', value: val } })}
                                        slots={{
                                            textField: TextField,
                                        }}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                onKeyDown: (e) => {
                                                    if (e.key === 'Tab') {
                                                        e.stopPropagation(); // Prevent focus from moving to the calendar icon
                                                    }
                                                },
                                            },
                                            openPickerButton: {
                                                tabIndex: -1, // Skip the calendar icon in the tab order
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth className="delivery_period_end">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        className="delivery_period_end"
                                        name="delivery_period_end"
                                        label="Delivery period end"
                                        format="MM/DD/YYYY"
                                        value={(state?.delivery_period_end && dayjs(state?.delivery_period_end)) || null}
                                        onChange={val => handleChangeState({ target: { name: 'delivery_period_end', value: val } })}
                                        slots={{
                                            textField: TextField,
                                        }}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                onKeyDown: (e) => {
                                                    if (e.key === 'Tab') {
                                                        e.stopPropagation(); // Prevent focus from moving to the calendar icon
                                                    }
                                                },
                                            },
                                            openPickerButton: {
                                                tabIndex: -1, // Skip the calendar icon in the tab order
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>


                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Contract #"
                                    name="contract_number"
                                    // required
                                    value={state?.contract_number || ''}
                                    onChange={handleChangeState}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 500 }} // Limit to 500 characters
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Notes"
                                    name="notes"
                                    // required
                                    defaultValue={state?.notes || ''}
                                    onChange={handleChangeState}
                                    InputLabelProps={{ shrink: true }}
                                    rows={4}
                                    multiline
                                    inputProps={{ maxLength: 500 }} // Limit to 500 characters
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>

            <DialogActions>
                <Stack direction="row-reverse" spacing={1} useFlexGap flexWrap="wrap" justifyContent='flex-end'>
                    <Button variant='contained' onClick={onSubmit}>OK</Button>
                    <Button variant='contained' color='secondary' autoFocus onClick={onClose}>Cancel</Button>
                </Stack>
            </DialogActions>


            <Dialog open={isCreateBuyerDialogOpen}>
                <DialogTitle>Create Buyer</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Buyer Name"
                                    name="buyer_financial_institution_name"
                                    value={state?.buyer_financial_institution_name || ""}
                                    required
                                    onChange={handleChangeState}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <TextField
                                    label="City"
                                    name="buyer_city_name"
                                    value={state?.buyer_city_name || ""}
                                    required
                                    onChange={handleChangeState}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <TextField
                                    label="State"
                                    name="buyer_state_name"
                                    value={state?.buyer_state_name || ""}
                                    required
                                    onChange={handleChangeState}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Stack direction="row-reverse" spacing={1} useFlexGap flexWrap="wrap" justifyContent='flex-end'>
                        <Button variant='contained' onClick={handleCreateBuyer}>OK</Button>
                        <Button variant='contained' color='secondary' autoFocus onClick={() => setIsCreateBuyerDialogOpen(false)}>Cancel</Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </>
    );
};