import { Button } from '@mui/material';
import { Amplify } from 'aws-amplify';
import { SnackbarProvider, useSnackbar } from 'notistack';
import ReactDOM from 'react-dom/client';
import App from './App';
import ThemeProvider from './ThemeProvider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { registerLicense } from '@syncfusion/ej2-base';
import { DataGridProvider } from 'core/AppContext';

import awsExports from './aws-exports';
Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById('root'))

// Syncfusion
registerLicense(process.env.REACT_APP_TILLEY_SYNCFUSION_LICENSE);

function SnackbarAction({
  id
}) {
  const { closeSnackbar } = useSnackbar();

  return (<>
    <Button
      onClick={() => closeSnackbar(id)}
      style={{
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
        border: 'none'
      }}
      variant="text"
    />
  </>);
}

root.render(
  <ThemeProvider>
    <DataGridProvider>
      <SnackbarProvider maxSnack={3} autoHideDuration={5000} disableWindowBlurListener={true}
        action={(id) => (
          <SnackbarAction id={id} />
        )}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <App />
      </SnackbarProvider>
    </DataGridProvider>
  </ThemeProvider>
)

// PWA ServiceWorker
serviceWorkerRegistration.register();
