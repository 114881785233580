import { Autocomplete, Box, FormControl, Stack, TextField, useMediaQuery } from '@mui/material';
import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { propUpdateReducer } from 'components/Core/propUpdateReducer'
import { AppContext } from 'App'

export default function AgentAutoComplete({
    brokers,
    onChange,
    selectedBroker
}) {

    const { globalState } = useContext(AppContext);

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'), { noSsr: true });

    const handleChange = (event, newValue) => {
        onChange(newValue);
    };

    return (
        <FormControl fullWidth>
            <Autocomplete
                multiple
                options={brokers || []}
                getOptionLabel={(option) => option?.last_name + ', ' + option?.first_name}
                value={selectedBroker || []}
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField
                        name="filter_agent_token"
                        {...params}
                        label="Broker"
                        required
                        InputLabelProps={{ shrink: true }}
                    />
                )}
                renderOption={(props, option) => {
                    const { key, ...optionProps } = props;
                    return (
                        <li key={key} {...optionProps}>
                            <Stack direction="row" spacing={1} key={key} sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                <Box>{option?.last_name + ', ' + option?.first_name}</Box>
                            </Stack>
                        </li>
                    );
                }}
            />
        </FormControl>
    )
}