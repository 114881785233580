import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { AppContext } from 'App';
import { Autocomplete, Box, Button, Dialog, FormControl, Grid, IconButton, InputAdornment, Stack, TextField, Tooltip, useMediaQuery } from '@mui/material';
import WorkspaceHeader from 'components/Workspaces/WorkspaceHeader';
import { FarmVaultIcon, MemberManagementIcon } from 'components/Icons/Icons';
import { DataGrid } from '@mui/x-data-grid'
import { AddCircleOutline, EastOutlined, SearchOutlined } from '@mui/icons-material';
import { ClientDialog, DataDictionaryDialog } from './ClientDialog';
import WorkspaceActionBar from 'components/Workspaces/WorkspaceActionBar';
import { _sessionManager, UsersApiHelper } from 'api/ApiHelper';
import { v4 } from 'uuid';
import { ApiHelper, USER_ROLE_ADMIN } from '@datanac/datanac-api-toolkit/dist/api-helper';
import { useSnackbar } from 'notistack';
import { StatefulControlledTextField } from 'components/Core/StatefulControlledTextField';
import { propUpdateReducer } from 'components/Core/propUpdateReducer';
import { formatProducerName } from 'utility/formatters';
import AgentAutoComplete from './AgentAutoComplete';

export default function ClientWorkspace() {
    const { globalState, globalDispatch } = useContext(AppContext);

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'), { noSsr: true });

    const [editingItem, setEditingItem] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    // ---

    const [isLoadingCurrent, setIsLoadingCurrent] = useState(false);

    const [userGrants, setUserGrants] = useState([]);
    const [selectedUserGrant, setSelectedUserGrant] = useState(null);


    const [brokers, setBrokers] = useState(null);
    const [isLoadingBrokers, setIsLoadingBrokers] = useState(false);

    const [filter, dispatchFilter] = useReducer(propUpdateReducer, {
        filter_agent_token: globalState?.filter_agent_token || [globalState?.agent_token] || [],
        search_producer_name: globalState?.search_text_portfolio,
    });
    const selectedBroker = useMemo(() => {
        const _selectedBroker = brokers?.filter(broker =>
            filter?.filter_agent_token?.includes(broker?.agent_token.toLowerCase())
        ) || [];

        return _selectedBroker;
    }, [brokers, filter?.filter_agent_token, globalState?.agent_token]);

    useEffect(() => {
        globalDispatch({
            type: 'update',
            payload: {
                key: "filter_agent_token",
                value: filter?.filter_agent_token,//array
            },
        });
    }, [filter?.filter_agent_token]);

    const filterHandleChangeState = (event, value) => {
        if (value == null && event.target.value != null) {
            value = event.target.value
        }

        dispatchFilter({
            type: 'update',
            payload: { key: event.target.name, value: value },
        });

        // Persist search text?
        if (event.target.name == 'search_producer_name' && value) {
            persistSearchText(event?.target?.value);
        }
    }

    const persistSearchText = async (searchText) => {
        globalDispatch({
            type: 'update',
            payload: {
                key: "search_text_portfolio",
                value: searchText,
            },
        });
    }

    const _loadClients = async () => {
        try {
            const _userGrantsDataFilter = {
                agent_token: globalState?.agent_token,
            };

            const promises = filter.filter_agent_token.map(async (agentToken) => {
                if (filter?.filter_agent_token && filter?.filter_agent_token.length) {
                    _userGrantsDataFilter.filter_agent_token = agentToken;
                }

                const apiClaims = {};
                if (globalState?.user_role != USER_ROLE_ADMIN) {
                    apiClaims.agent_token = globalState?.agent_token;
                }

                return await UsersApiHelper.users_search("Client", _userGrantsDataFilter, apiClaims);
            });

            const allResults = await Promise.all(promises);
            setUserGrants(allResults.flat());
        } catch (error) {
            handleError(error);
        }
    };

    useEffect(() => {
        _loadClients();
    }, [filter]);


    const loadBrokers = async () => {
        try {
            if (globalState?.agent_token) {
                setIsLoadingBrokers(true);

                const _brokersFilter = {
                    agent_token: globalState?.agent_token,
                };
                // This is agent function. Do not specify producer_token or you will get permissions error.
                const _apiConfig = {
                    agent_token: globalState?.agent_token,
                };
                const _brokers = await UsersApiHelper.users_search("AgencyAgent", _brokersFilter, _apiConfig);
                setBrokers(_brokers);
                setIsLoadingBrokers(false);
            } else if (globalState?.user_role == USER_ROLE_ADMIN) {
                setIsLoadingBrokers(true);

                const _brokersFilter = {};
                // This is ADMIN function. Do not specify producer_token or agent_token or you will get permissions error.
                const _apiConfig = {};
                const _brokers = await UsersApiHelper.users_search("AgencyAgent", _brokersFilter, _apiConfig);

                setBrokers(_brokers);
                setIsLoadingBrokers(false);
            }
        } catch (err) {
            setIsLoadingBrokers(false);
            setBrokers(null);
            handleError(err);
        }
    }

    useEffect(() => {
        if (globalState?.agent_token || globalState?.user_role == USER_ROLE_ADMIN) {
            setIsLoadingBrokers(true);
            loadBrokers();
        }
    }, [globalState?.agent_token, globalState?.user_role, filter]);

    const handleError = (err) => {
        console.warn(err);
        enqueueSnackbar(err.detail || err.message || JSON.stringify(err), { preventDuplicate: true, variant: 'error' });
    }

    useEffect(() => {
        const _loadMember = async () => {
            try {
                if (selectedUserGrant) {
                    // Get Member:
                    const _members = await UsersApiHelper.users_selectObject("Member", { producer_token: selectedUserGrant?.producer_token }, {});
                    if (_members?.length) {
                        setEditingItem(_members[0]);
                    } else {
                        setEditingItem(null);
                    }
                }
            } catch (err) {
                handleError(err);
            }
        }
        _loadMember();
    }, [selectedUserGrant]);

    // ---

    const _formatProducerNameCallback = useCallback((row) => {
        return formatProducerName(row);
    }, []);

    const columns = useMemo(() => [
        {
            field: 'producer_token',
            headerName: 'Client',
            minWidth: 200,
            flex: 3,
            valueGetter: (params) => {
                return _formatProducerNameCallback(params.row);
            },
        },
        {
            field: '',
            headerName: ' ',
            renderCell: (params) => (
                <>
                    <Stack direction='row'>
                        <IconButton onClick={() => setSelectedUserGrant(params.row)}>
                            <MemberManagementIcon />
                        </IconButton>
                    </Stack>
                </>
            ),
            flex: 1
        }
    ], []);

    const handleChange = async (account) => {
        try {
            const api = new ApiHelper({
                baseUrl: process.env.REACT_APP_DATANAC_USERS_API_URL_BASE,
                config: {},
                sessionManager: _sessionManager,
                // No caching:
                _instance: null
            });

            const _accountParams = { ...account };
            _accountParams.producer_token = v4();
            _accountParams.agent_token = globalState?.agent_token;

            await api.callPost("marketing/Member", _accountParams, globalState);

            // Refresh list
            _loadClients();
        } catch (err) {
            handleError(err);
        }
    }

    const handleAddNewClick = () => {
        setEditingItem({
            id: v4(),
            is_active: true,
        });
    }

    const handleDialogClose = async () => {
        setEditingItem(null);
        setSelectedUserGrant(null);
    }

    const filtereduserGrants = useMemo(() =>
        userGrants?.filter((row) => {
            if (filter?.search_producer_name) {
                return formatProducerName(row)?.toLowerCase().includes(filter?.search_producer_name.toLowerCase());
            }
            return true;
        }),
        [userGrants, filter?.search_producer_name]);

    const handleChangeAgentToken = async (agentTokens) => {
        let agents = agentTokens.map((item) => (item.agent_token));

        dispatchFilter({
            type: 'update',
            payload: { key: 'filter_agent_token', value: agents },
        });
    }

    // --- --- ---

    return (
        <>
            <WorkspaceHeader
                title='Clients'
                breadCrumbs={[{
                    title: 'Marketing',
                    icon: <FarmVaultIcon />
                }]}
            />

            <Grid container>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} className='headerWithMenuButton marketing-position'>
                        <AgentAutoComplete brokers={brokers} selectedBroker={selectedBroker} onChange={handleChangeAgentToken} />

                        <StatefulControlledTextField
                            label="Search"
                            name="search_producer_name"
                            isRequired={false}
                            defaultValue={filter?.search_producer_name || ''}
                            onChange={filterHandleChangeState}
                            sx={{ minWidth: '2in', maxWidth: '100%' }}
                            endAdornment={
                                <Tooltip title='Search'>
                                    <InputAdornment position='end'>
                                        <IconButton edge="end" color="primary">
                                            <SearchOutlined />
                                        </IconButton>
                                    </InputAdornment>
                                </Tooltip>
                            }
                        />

                        {isLoadingCurrent && <CircularProgress />}
                    </Stack>
                </Grid>
            </Grid>

            <DataGrid
                rows={filtereduserGrants || []}
                columns={columns}
                // getRowId={(row, index) => index}
                initialState={{
                    pagination: { paginationModel: { pageSize: isMobile ? 5 : 10 } },
                }}
                pageSizeOptions={[5, 10, 25]}
                autoHeight
            />

            <Dialog open={Boolean(editingItem != null)}>
                <ClientDialog member={editingItem}
                    onChange={handleChange} onClose={handleDialogClose} />
            </Dialog>

            <WorkspaceActionBar content={<>
                <Box>
                    <Button
                        variant="contained"
                        sx={{ width: '100%' }}
                        onClick={handleAddNewClick}
                    >
                        <AddCircleOutline sx={{ mr: 1 }} />
                        New Client
                    </Button>
                </Box>
            </>} />

        </>
    );
}