import React, { useContext, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import DataGridContext from "core/AppContext";

const TilleyDataGrid = ({ gridKey, pageSizeOptions = [5, 10, 15, 25, 50, 100], defaultPageSize = 25, rows, columns, getRowId }) => {
    const { getGridState, setGridState } = useContext(DataGridContext);

    const initialState = getGridState()[gridKey] || {
        pageSize: defaultPageSize,
        sorting: null,
    };

    const [paginationModel, setPaginationModel] = useState({
        pageSize: initialState.pageSize,
        page: 0,
    });

    const [sortModel, setSortModel] = useState(
        initialState.sorting ? [initialState.sorting] : [] // Ensure an empty array if no sorting is defined
    );

    useEffect(() => {
        const savedState = getGridState()[gridKey];
        if (savedState) {
            setPaginationModel((prev) => ({
                ...prev,
                pageSize: savedState.pageSize || defaultPageSize,
            }));
            setSortModel(savedState.sorting ? [savedState.sorting] : []); // Validate sorting before setting
        }
    }, [getGridState, gridKey, defaultPageSize]);

    const handlePaginationModelChange = (model) => {
        setPaginationModel(model);
        setGridState(gridKey, { pageSize: model.pageSize });
    };

    const handleSortModelChange = (model) => {
        setSortModel(model);
        setGridState(gridKey, { sorting: model[0] || null }); // Save only the first sorting object or null
    };

    return (
        <DataGrid
            rows={rows}
            columns={columns}
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            pageSizeOptions={pageSizeOptions}
            getRowId={getRowId}
            autoHeight
        />
    );
};

export default TilleyDataGrid;